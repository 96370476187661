body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Estilo para el tema por defecto */
body[data-theme='system'] {
  background-color: #f8f9fa;
  color: #212529;
}

/* Estilo para el tema claro */
body[data-theme='white'] {
  background-color: #ffffff;
  color: #000000;
}

/* Estilo para el tema oscuro */
body[data-theme='black'] {
  background-color: #121212;
  color: #ffffff;
}


/* Efecto hover para las tarjetas */
.hover-effect {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.hover-effect:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


